.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px; /* Ajusta el relleno interno si lo necesitas */
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.icon-container svg {
  transition: transform 0.3s ease-in-out;
}

.icon-container:hover svg {
  transform: scale(1.2); /* Efecto de zoom al pasar el ratón */
}

.subtitle {
  color: white;
  font-size: 1.2rem;
  margin-top: 10px;
}
