/* Estilo principal del contenedor */
.gym-locations {
  background-color: #222222; /* Fondo oscuro */
  color: #ffffff; /* Letra blanca */
  text-align: center;
  padding: 2rem;
}



.locations-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

/* Estilo de cada tarjeta */
.location-card {
  width: 400px;
  position: relative;
  text-align: center;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px; /* Bordes redondeados para las imágenes */
}

.image-container img {
  width: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 8px; /* Asegura que la imagen sigue el borde redondeado */
}

/* Efecto hover en la imagen */
.image-container:hover img {
  filter: brightness(50%);
}

/* Estilo del botón */
.view-more-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fe0000; /* Botón rojo */
  color: #ffffff; /* Texto blanco */
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: bold;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

/* Oscurecer el botón al pasar el ratón */
.view-more-button:hover {
  background-color: #cc0000; /* Botón más oscuro */
}

/* Mostrar el botón al pasar el ratón por la imagen */
.image-container:hover .view-more-button {
  opacity: 1;
}

/* Estilo del texto de la ciudad */
h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #ffffff; /* Letra blanca */
}
