.path-guide-container {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 50px;
  background-color: #222222;
  color: white;
  min-height: 100vh;
}


.steps-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.step.active {
  opacity: 1;
}

.left {
  text-align: left;
  flex-direction: row-reverse; /* Cambiar para que el icono vaya antes */
}

.right {
  text-align: right;
  flex-direction: row; /* Cambiar para que el icono vaya después */
}

.step-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  padding: 20px;
}

.step-icon {
  display: flex; /* Usamos flexbox para centrar el icono */
  align-items: center; /* Centrar verticalmente */
  justify-content: center; /* Centrar horizontalmente */
  padding: 10px;
  margin-top: 5vh;
  color: #eb3646;
}

.step-icon-left {
  margin-right: 20px;
  transition: transform 0.3s ease-in-out;
  order: -1; /* El icono va antes del texto en los pasos "izquierda" */
}

.step-icon-right {
  margin-left: 20px;
  transition: transform 0.3s ease-in-out;
  order: 1; /* El icono va después del texto en los pasos "derecha" */
}

.step-icon.active {
  transform: scale(1.2); /* Efecto de zoom al pasar al paso activo */
}

.step-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.step-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #eb3646;
}

.step-description {
  font-size: 1rem;
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  text-align: justify; /* Justifica el texto */
  line-height: 1.8; /* Aumenta el espacio entre las líneas */
}


/* Responsive Design */

/* Ajustes para tabletas */
@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
  }

  .step-icon-left {
    margin-right: 0px;

  }
  
  .step-icon-right {
    margin-left: 0px;

  }

  .step {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .step-text {
    text-align: center;
  }
  .step-content {
    display: block;

  }
}

/* Ajustes para móviles */
@media (max-width: 480px) {
  .path-guide-container h1 {
    font-size: 1.8rem;
  }

  .step-icon {
    font-size: 2rem;
    padding: 10px;
  }

  .step-text h2 {
    font-size: 1.2rem;
  }

  .step-text p {
    font-size: 0.9rem;
  }
}



