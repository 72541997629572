.under-construction {
  text-align: center;
  padding: 50px;
  font-family: Arial, sans-serif;
  color: #ffffff; /* Letra blanca */
  background-color: #222222; /* Fondo oscuro */
  height: 100vh; /* Ocupa toda la pantalla */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-container {
  font-size: 100px;
  color: #ffa500; /* Naranja para destacar sobre el fondo */

}



.icon2 {
  font-size: 50px;

}
.animation {
  width: 150px;
  margin-top: 20px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
